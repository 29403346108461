<template>
  <v-card class="pa-5" flat>
    <div class="d-flex">
      <div>
        <span class="font-weight-bold">Thực hiện bởi: </span>
        {{ authUser.name }}
      </div>
      <v-spacer></v-spacer>
      <div>{{ new Date().toDateString() }}</div>
    </div>

    <div class="pt-4">
      <div class="text-h6 font-weight-medium mb-2">Máy gốc</div>
      <v-card v-if="!oldOption.option_sku" class="tp-border-thin" flat>
        <v-card-title class="text-body-1 grey lighten-5 justify-center">
          Chưa được chọn
        </v-card-title>
      </v-card>
      <card-serial-info
        v-else
        :item="oldOption"
        :remove="removeOldOne"
      ></card-serial-info>
    </div>

    <div class="pt-4">
      <div class="text-h6 font-weight-medium mb-2">Máy sau điều chỉnh</div>
      <v-card v-if="!newOption.SKU" class="tp-border-thin" flat>
        <v-card-title class="text-body-1 grey lighten-5 justify-center">
          Chưa được chọn
        </v-card-title>
      </v-card>
      <card-option-info
        v-else
        :item="newOption"
        :remove="removeNewOne"
      ></card-option-info>
    </div>

    <v-divider class="mt-5"></v-divider>

    <div class="pt-4">
      <div class="text-h6 font-weight-medium mb-2">
        Linh kiện ({{ gears.length }})
      </div>
      <v-card v-if="!gears.length" class="tp-border-thin" flat>
        <v-card-title class="text-body-1 grey lighten-5 justify-center py-10">
          Chưa được chọn
        </v-card-title>
      </v-card>
      <template v-else>
        <card-serial-info
          v-for="(gear, index) in gears"
          v-bind:key="index"
          class="mt-3"
          :item="gear"
          :remove="removeGearOne"
        ></card-serial-info>
      </template>
    </div>
  </v-card>
</template>

<script>
import CardSerialInfo from "./CardSerialInfo";
import CardOptionInfo from "./CardOptionInfo";

export default {
  components: {
    CardSerialInfo,
    CardOptionInfo
  },
  data() {
    return {};
  },
  computed: {
    oldOption() {
      return this.$store.getters["ADD_ACCESS/oldOption"];
    },
    newOption() {
      return this.$store.getters["ADD_ACCESS/newOption"];
    },
    gears() {
      return this.$store.getters["ADD_ACCESS/gears"];
    },
    addAccessStatusRequest() {
      return this.$store.getters["ADD_ACCESS/statusRequest"];
    },
    authUser() {
      return this.$store.getters["AUTHENTICATION/user"];
    }
  },
  methods: {
    removeOldOne() {
      this.$store.dispatch("ADD_ACCESS/removeOldOne");
    },
    removeNewOne() {
      this.$store.dispatch("ADD_ACCESS/removeNewOne");
    },
    removeGearOne(index) {
      this.$store.dispatch("ADD_ACCESS/removeGearOne", index);
    }
  }
};
</script>

<style lang="scss" scoped></style>
