<template>
  <v-card class="tp-border-thin rounded px-3 py-2" flat>
    <v-row class="align-center" no-gutters>
      <v-col cols="8">
        <div class="font-weight-bold mb-1">
          {{ item.product_name }}
        </div>
        <div class="">
          <span class="font-weight-bold">SKU:</span>
          <span> {{ item.option_sku }}</span>
          <span v-if="item.option_name"> - </span>
          <span class="tag-p__mb-0" v-html="item.option_name"></span>
        </div>
        <div class="">
          <span class="font-weight-bold">Giá bán: </span>
          <span>{{ item.price | formatCurrency }}</span>
        </div>
      </v-col>
      <v-col class="d_flex flex-column justify-center align-center">
        <div class="font-weight-bold mb-1">Serial</div>
        <div class="">
          <v-chip class="cyan lighten-4 font-weight-medium rounded-lg" label>
            {{ item.serial_number }}
          </v-chip>
        </div>
      </v-col>
      <v-col class="d_flex justify-end" cols="1">
        <v-hover v-slot:default="{ hover }" v-if="!disableClose">
          <v-btn
            :color="hover ? 'grey darken-1' : 'grey lighten-1'"
            icon
            @click="remove"
          >
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-hover>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    disableClose: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object
    },
    remove: {
      type: Function
    }
  }
};
</script>
